<template>
  <div class="card bg-light">
    <div class="card-header">
      <h6 class="mb-0">
        {{ $t('configurationFundraise.roundModal.label.jurisdictionalExceptions') }}
      </h6>
    </div>
    <div class="card-body">
      <div
        v-for="(item, index) in items"
        :key="index + (item.countryCode || null)"
        class="row align-items-start mb-3"
      >
        <div class="col-md-4">
          <div>
            <cp-select
              v-model="item.countryStateKey"
              name="countryStateKey"
              input-class="mb-0"
              :error="getCountryCodeError(item)"
              :options="countryOptions"
              :disabled="disabled"
              @input="checkAndUpdateRow(item, index, {countryStateKey: $event})"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <cp-select
              v-model="item.exceptionName"
              :input-class="['mb-0',{'border border-danger': isDuplicatedRow(item)}]"
              :error="getJurisdictionalNameError(item)"
              :options="jurisdictionalExceptionNameOptions"
              :disabled="disabled"
              name="jurisdictionalExceptionName"
              @input="checkAndUpdateRow(item, index, {exceptionName: $event})"
            />
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-center">
          <cp-input
            v-if="showSimpleInput(item)"
            :key="index + (item.exceptionName || null)"
            v-model="item.exceptionValue"
            name="jurisdictionalExceptionValue"
            :error="getValueError(item)"
            :disabled="disabled"
            input-class="mb-0"
            @input="checkAndUpdateRow(item, index, {exceptionValue: $event})"
          />
          <div
            v-else
            class="input-group mt-0"
          >
            <cp-input
              v-model="item.exceptionValue"
              class="align-min-investment-value"
              input-type="number"
              input-class="mb-0"
              name="jurisdictionalExceptionValue"
              :error="getValueError(item)"
              :disabled="disabled"
              @input="checkAndUpdateRow(item, index, {exceptionValue: $event})"
            />
            <div class="input-group-append">
              <span class="form-control">{{ issuerMainCurrencyIdentifier }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-1">
          <b-btn
            v-b-tooltip.hover
            variant="default borderless md-btn-flat icon-btn text-muted"
            title="Move to trash"
            :disabled="disabled"
            @click="deleteItem(index)"
          >
            <i class="ion ion-md-trash" />
          </b-btn>
        </div>
        <div class="col-md-1">
          <b-btn
            v-b-tooltip.hover
            variant="default borderless md-btn-flat icon-btn text-muted"
            title="Add item"
            :disabled="canAddNewRow() || disabled"
            @click="createNewItem(index)"
          >
            <i class="ion ion-ios-add-circle-outline" />
          </b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { CpSelect, CpInput } from '~/components/common/standalone-components/inputs';

export default {
  name: 'JurisdictionalExceptions',
  components: {
    CpSelect,
    CpInput,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    const exceptionNames = {
      docusignId: 'docusign-id',
      custodianDocusignId: 'custodian-docusign-id',
      minInvestmentFiat: 'min-investment-fiat',
      minInvestmentCrypto: 'min-investment-crypto',
    };

    return {
      exceptionNames,
      items: this.data.length ? this.data.map(item => ({ ...item, countryStateKey: this.getCountryStateKey(item), errors: new Set() })) : [{ errors: new Set() }],
      errorTypes: {
        exceptionValueRequired: 'exceptionValueRequired',
        exceptionValueWrong: 'exceptionValueWrong',
        countryCodeRequired: 'countryCodeRequired',
        exceptionNameRequired: 'exceptionNameRequired',
        isDuplicated: 'isDuplicated',
      },
      jurisdictionalExceptionNameOptions: [
        { value: exceptionNames.docusignId, text: 'Docusign id' },
        { value: exceptionNames.custodianDocusignId, text: 'Custodian DocuSign ID' },
        { value: exceptionNames.minInvestmentFiat, text: 'Min. investment fiat' },
        { value: exceptionNames.minInvestmentCrypto, text: 'Min. investment crypto' },
      ],
    };
  },
  computed: {
    ...mapGetters('global', ['countries', 'countryByCode']),
    ...mapGetters('jurisdictions', ['jurisdictionsStatesAndRegions']),
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    countryOptions() {
      const statesByCountryCode = (this.jurisdictionsStatesAndRegions || []).reduce((result, { countryCode, stateAndRegionName, stateAndRegionCode }) => {
        if (!result[countryCode]) result[countryCode] = [];
        result[countryCode].push({ stateAndRegionName, stateAndRegionCode });

        return result;
      }, {});

      return (this.countries || []).reduce((result, { name: countryName, countryCode }) => {
        result.push({ text: countryName, value: countryCode });
        if (statesByCountryCode[countryCode]) {
          statesByCountryCode[countryCode].forEach(({ stateAndRegionName, stateAndRegionCode }) => {
            result.push({ text: `${countryName} - ${stateAndRegionName}`, value: `${countryCode}|${stateAndRegionCode}` });
          });
        }

        return result;
      }, []);
    },
  },
  methods: {
    getCountryStateKey({ countryCode, stateAndRegionCode }) {
      return stateAndRegionCode ? `${countryCode}|${stateAndRegionCode}` : countryCode;
    },
    checkDuplicatesError(item, index) {
      const { countryStateKey, exceptionName } = item;

      const hasDuplicates = this.items.some(({ countryStateKey: currentCountryStateKey, exceptionName: currentExceptionName }, currentIndex) => {
        if (currentIndex === index) return false;
        return currentCountryStateKey === countryStateKey && currentExceptionName === exceptionName;
      });

      const method = hasDuplicates ? 'add' : 'delete';
      item.errors[method](this.errorTypes.isDuplicated);
    },
    checkValueError(item) {
      const { exceptionValue, exceptionName } = item;

      const validations = {
        [this.exceptionNames.docusignId]: this.checkDocusignId,
        [this.exceptionNames.custodianDocusignId]: this.checkDocusignId,
        [this.exceptionNames.minInvestmentFiat]: this.checkMinInvestmentValue,
        [this.exceptionNames.minInvestmentCrypto]: this.checkMinInvestmentValue,
      };

      if (validations[exceptionName]) validations[exceptionName].call(this, exceptionValue, item);
    },
    checkDocusignId(value, { errors }) {
      errors[value ? 'delete' : 'add'](this.errorTypes.exceptionValueRequired);
    },
    checkMinInvestmentValue(value, { errors }) {
      if (value === undefined || value === null) return errors.add(this.errorTypes.exceptionValueRequired);
      errors.delete(this.errorTypes.exceptionValueRequired);

      if (Number.isNaN(value) || value <= 0) return errors.add(this.errorTypes.exceptionValueWrong);
      errors.delete(this.errorTypes.exceptionValueWrong);
    },
    passDataToParent() {
      this.$emit('change', { data: this.getDataForParentComponent(), error: this.filterNewEmptyItems().some(({ errors }) => errors.size) });
    },
    checkAndSetErrors(item, index) {
      const { countryStateKey, exceptionName } = item;
      if (!countryStateKey) return item.errors.add(this.errorTypes.countryCodeRequired);

      item.errors.delete(this.errorTypes.countryCodeRequired);

      if (!exceptionName) return item.errors.add(this.errorTypes.exceptionNameRequired);

      item.errors.delete(this.errorTypes.exceptionNameRequired);

      this.checkDuplicatesError(item, index);
      this.checkValueError(item);
      this.$set(this.items, index, item);
    },
    checkAndUpdateRow(item, index, data) {
      item = { ...item, ...data };
      this.checkAndSetErrors(item, index);
      this.passDataToParent();
    },
    getCountryCodeError({ errors }) {
      if (errors.has(this.errorTypes.countryCodeRequired)) return this.$t('configurationFundraise.roundModal.message.countryCodeRequired');
      if (errors.has(this.errorTypes.isDuplicated)) return this.$t('configurationFundraise.roundModal.message.jurisdictionalExceptionExists');

      return '';
    },
    getJurisdictionalNameError({ errors }) {
      if (errors.has(this.errorTypes.exceptionNameRequired)) return this.$t('configurationFundraise.roundModal.message.exceptionNameRequired');

      return '';
    },
    getValueError({ errors }) {
      if (errors.has(this.errorTypes.exceptionValueWrong)) return this.$t('configurationFundraise.roundModal.message.wrongExceptionValue');
      if (errors.has(this.errorTypes.exceptionValueRequired)) return this.$t('configurationFundraise.roundModal.message.exceptionValueRequired');

      return '';
    },
    showSimpleInput({ exceptionName }) {
      return !exceptionName || exceptionName === this.exceptionNames.docusignId || exceptionName === this.exceptionNames.custodianDocusignId;
    },
    isDuplicatedRow({ errors }) {
      return errors.has(this.errorTypes.isDuplicated);
    },
    canAddNewRow() {
      return this.filterNewEmptyItems().some(({ errors }) => errors.length);
    },
    filterNewEmptyItems() {
      return this.items.filter(({ countryCode, exceptionName, exceptionValue }) => countryCode !== undefined || exceptionName !== undefined || exceptionValue !== undefined);
    },
    createNewItem(index) {
      this.items.splice(index + 1, 0, { errors: new Set() });
    },
    deleteItem(key) {
      this.items.splice(key, 1);
      this.recheckAllDuplicates();
      this.passDataToParent();

      if (!this.items.length) this.items.push({ errors: new Set() });
    },
    recheckAllDuplicates() {
      this.items.forEach((item, index) => this.checkDuplicatesError(item, index));
    },
    getDataForParentComponent() {
      return this.filterNewEmptyItems().map(({ countryStateKey, exceptionName, exceptionValue }) => {
        const [countryCode, stateAndRegionCode = null] = countryStateKey.split('|');

        return { countryCode, stateAndRegionCode, exceptionName, exceptionValue };
      });
    },
  },
};

</script>

<style lang="scss" scoped>
  .align-min-investment-value {
    width: 64%;
  }
</style>
