<template>
  <div>
    <div
      v-if="loading"
      class="row"
    >
      <div class="col-12 text-center mt-4">
        {{ $t('configurationFundraise.loading') }}
      </div>
    </div>
    <div v-else>
      <cp-configuration-head
        :page-name="$t('configurationFundraise.title')"
      />
      <cp-general-component
        :general="fundraiseConfigurations"
        @onCancel="updateConfigs"
      />
      <round-component />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpConfigurationHead from '../configuration-head';
import RoundComponent from './components/round';
import CpGeneralComponent from './components/general-component';

export default {
  name: 'Fundraise',
  metaInfo: {
    title: 'Fundraise',
  },
  components: {
    CpConfigurationHead,
    RoundComponent,
    CpGeneralComponent,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: mapGetters('fundraise', ['fundraiseConfigurations']),
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.updateConfigs();
      },
    },
  },
  methods: {
    ...mapActions('fundraise', ['getFundraiseTokenConfiguration']),
    updateConfigs() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      this.getFundraiseTokenConfiguration({ issuerId, tokenId }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
