<template>
  <div>
    <div class="card mb-5 fundraise-round">
      <cp-card-head
        :card-name="$t('configurationFundraise.subTitle.round')"
        without-edit
        :create-elem="!isEvergreenRound"
        :button-title="$t('configurationFundraise.button.round.add')"
        button-icon="ios-add-circle-outline"
        @onCreateElem="createModal"
      />
      <cp-round-table
        ref="cpRoundTable"
        @onEditElem="editModal"
        @onDeleteElem="deleteModal"
        @onTokenConfigurationChange="tokenConfigurationChange"
      />
      <cp-round-modal
        ref="cpRoundModal"
        :all-rounds="tableData"
        :round-info="currentRound"
        :edit-mode="editMode"
        :title="roundModal.title"
        :title-icon="roundModal.icon"
        :disabled="isDoneRoundStatus"
        :is-evergreen-round="isEvergreenRound"
        @onSubmit="submitRound"
      />
      <cp-confirm-modal
        ref="cpDeleteRoundModal"
        :title="$t('configurationFundraise.deleteModal.title')"
        :item="currentRound"
        @onOk="deleteCurrentRound"
      >
        {{ confirmDeleteModalText }}
      </cp-confirm-modal>
      <cp-confirm-modal
        ref="cpUpdateDoneRoundModal"
        :title="$t('configurationFundraise.updateDoneRoundModal.title')"
        :item="updatedDoneRound"
        @onOk="updateCurrentRound"
      >
        {{ $t('configurationFundraise.updateDoneRoundModal.message') }}
      </cp-confirm-modal>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import CpCardHead from './card-head';
import CpRoundTable from './table';
import CpRoundModal from './cp-round-modal';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'RoundComponent',
  components: {
    CpCardHead,
    CpRoundModal,
    CpRoundTable,
    CpConfirmModal,
  },
  data() {
    return {
      currentRound: {},
      updatedDoneRound: {},
      editMode: false,
      roundModal: {
        title: '',
        icon: '',
      },
      confirmDeleteModalText: '',
      tableData: [],
      isEvergreenRound: null,
    };
  },
  computed: {
    ...mapGetters('jurisdictions', ['jurisdictionsStatesAndRegions']),
    isDoneRoundStatus() {
      return this.currentRound.status === 'done';
    },
  },
  created() {
    if (!this.jurisdictionsStatesAndRegions.length) {
      const issuerId = this.$route.params.idIssuer;
      this.getJurisdictionsStatesAndRegions(issuerId);
    }
  },
  methods: {
    ...mapActions('jurisdictions', ['getJurisdictionsStatesAndRegions']),
    ...mapActions('rounds', [
      'createRound',
      'updateRound',
      'deleteRound',
      'getRounds',
      'getInvestmentRoundById',
    ]),
    tokenConfigurationChange({ isEvergreenRound }) {
      this.isEvergreenRound = isEvergreenRound;
    },
    createModal() {
      this.getTableData();
      this.currentRound = {};
      this.updatedDoneRound = {};
      this.editMode = false;
      this.roundModal.title = this.$t('configurationFundraise.roundModal.title.add');
      this.roundModal.icon = 'ios-add-circle-outline';
      this.showRoundModal();
    },
    async editModal(round) {
      const { id: roundId, issuerId, tokenId } = round;
      this.getTableData();
      this.currentRound = await this.getInvestmentRoundById({ issuerId, roundId, tokenId });
      this.editMode = true;
      this.roundModal.title = this.$t('configurationFundraise.roundModal.title.edit');
      this.roundModal.icon = 'md-create';
      this.showRoundModal();
    },
    deleteModal(round) {
      this.currentRound = round;
      this.confirmDeleteModalText = this.$t('configurationFundraise.deleteModal.message', [round.name]);
      this.showDeleteRoundModal();
    },
    submitRound(elem) {
      const { body } = elem;

      if (elem.editMode && this.isDoneRoundStatus) this.handleUpdateDoneRound(body);
      if (elem.editMode && !this.isDoneRoundStatus) this.updateCurrentRound(body);
      if (!elem.editMode) this.createNewRound(body);
    },
    updateCurrentRound(body) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      const { roundId } = body;

      this.updateRound({
        issuerId,
        tokenId,
        roundId,
        body,
      }).then(() => {
        this.updateTable();
        this.hideRoundModal();
        this.getRounds({ issuerId, tokenId });
      });
    },
    createNewRound(body) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      this.createRound({
        issuerId,
        tokenId,
        body,
      }).then(() => {
        this.updateTable();
        this.hideRoundModal();
        this.getRounds({ issuerId, tokenId });
      });
    },
    deleteCurrentRound(round) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      this.deleteRound({
        issuerId,
        tokenId,
        roundId: round.id,
      }).then(() => {
        this.updateTable();
        this.hideDeleteRoundModal();
        this.getRounds({ issuerId, tokenId });
      });
    },
    handleUpdateDoneRound(body) {
      const isUpdatingDoneRound = this.isDoneRoundStatus && body.status === 'active';
      if (isUpdatingDoneRound) {
        this.updatedDoneRound = body;
        this.showUpdateDoneRoundModal();
      } else {
        this.hideRoundModal();
      }
    },
    showRoundModal() {
      this.$refs.cpRoundModal.show();
    },
    hideRoundModal() {
      this.$refs.cpRoundModal.hide();
    },
    showDeleteRoundModal() {
      this.$refs.cpDeleteRoundModal.show();
    },
    hideDeleteRoundModal() {
      this.$refs.cpDeleteRoundModal.hide();
    },
    showUpdateDoneRoundModal() {
      this.$refs.cpUpdateDoneRoundModal.show();
    },
    updateTable() {
      this.$refs.cpRoundTable.$refs.cpTable.updateTableData();
    },
    getTableData() {
      this.tableData = this.$refs.cpRoundTable.$refs.cpTable.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .fundraise-round {
    .table {
      tr {
        td:nth-child(8), td:nth-child(9) {
          white-space: nowrap;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .card-header {
      border-bottom: none;
    }
  }
</style>
