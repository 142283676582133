export default [
  {
    key: 'name',
    i18nKey: 'configurationFundraise.label.table.name',
    tdClass: 'align-middle',
    thClass: 'align-middle',
    sortable: true,
  },
  {
    key: 'status',
    i18nKey: 'configurationFundraise.label.table.status',
    tdClass: 'align-middle',
    thClass: 'align-middle',
    sortable: true,
  },
  {
    key: 'startsAt',
    i18nKey: 'configurationFundraise.label.table.startsAt',
    tdClass: 'align-middle',
    thClass: 'align-middle',
    sortable: true,
  },
  {
    key: 'endsAt',
    i18nKey: 'configurationFundraise.label.table.endsAt',
    tdClass: 'align-middle',
    thClass: 'align-middle',
    sortable: true,
  },
  {
    key: 'issuanceDate',
    i18nKey: 'configurationFundraise.label.table.issuanceDate',
    tdClass: 'align-middle',
    thClass: 'align-middle',
    sortable: true,
  },
  {
    key: 'minInvestmentCrypto',
    i18nKey: 'configurationFundraise.label.table.minimumCryptoInvestments',
    tdClass: 'align-middle',
    thClass: 'align-middle',
    sortable: true,
  },
  {
    key: 'minInvestmentFiat',
    i18nKey: 'configurationFundraise.label.table.minimumFiatInvestments',
    tdClass: 'align-middle',
    thClass: 'align-middle',
    sortable: true,
  },
  {
    key: 'investmentTermsAndConditions',
    i18nKey: 'configurationFundraise.label.table.investmentTermsAndConditions',
    tdClass: 'align-middle',
    thClass: 'align-middle',
  },
  {
    key: 'investmentSubscriptionAgreement',
    i18nKey: 'configurationFundraise.label.table.investmentSubscriptionAgreement',
    tdClass: 'align-middle',
    thClass: 'align-middle',
  },
  {
    key: 'subscriptionAgreementDocusignId',
    i18nKey: 'configurationFundraise.label.table.subscriptionAgreementDocusignId',
    tdClass: 'align-middle',
    thClass: 'align-middle',
  },
  {
    key: 'actions',
    label: ' ',
    tdClass: 'text-nowrap align-middle text-center',
    thClass: 'align-middle',
  },
];
