var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-table',{ref:"cpTable",attrs:{"without-search":"","get-data-action":"fundraise/getIssuerInvestmentRounds","url-params":_vm.urlParams,"filters":{ isEvergreenRound: _vm.tokenConfiguration.evergreenRound },"fields":_vm.fieldsLocalized},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.$t(("configurationFundraise.status." + (rowData.item.status))))+" ")]}},{key:"startsAt",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.startsAt))+" ")]}},{key:"endsAt",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.endsAt))+" ")]}},{key:"issuanceDate",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.issuanceDate))+" ")]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default action-button edit-operator-btn btn-xs md-btn-flat","title":"Edit"},on:{"click":function($event){return _vm.$emit('onEditElem', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create"})]),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default action-button btn-xs md-btn-flat","title":"Delete"},on:{"click":function($event){return _vm.$emit('onDeleteElem', rowData.item)}}},[_c('i',{staticClass:"ion ion-ios-trash"})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }