<template>
  <cp-table
    ref="cpTable"
    without-search
    get-data-action="fundraise/getIssuerInvestmentRounds"
    :url-params="urlParams"
    :filters="{ isEvergreenRound: tokenConfiguration.evergreenRound } "
    :fields="fieldsLocalized"
  >
    <template
      slot="status"
      slot-scope="{ rowData }"
    >
      {{ $t(`configurationFundraise.status.${rowData.item.status}`) }}
    </template>
    <template
      slot="startsAt"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.startsAt | dateFilter }}
    </template>
    <template
      slot="endsAt"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.endsAt | dateFilter }}
    </template>
    <template
      slot="issuanceDate"
      slot-scope="{ rowData }"
    >
      {{ rowData.item.issuanceDate | dateFilter }}
    </template>
    <template
      slot="actions"
      slot-scope="{ rowData }"
    >
      <b-btn
        v-b-tooltip.hover="true"
        variant="default action-button edit-operator-btn btn-xs md-btn-flat"
        title="Edit"
        @click="$emit('onEditElem', rowData.item)"
      >
        <i class="ion ion-md-create" />
      </b-btn>
      <b-btn
        v-b-tooltip.hover="true"
        variant="default action-button btn-xs md-btn-flat"
        title="Delete"
        @click="$emit('onDeleteElem', rowData.item)"
      >
        <i class="ion ion-ios-trash" />
      </b-btn>
    </template>
  </cp-table>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CpTable from '~/components/shared/cp-table';
import fields from '../fields';

export default {
  name: 'CpConfigurationRoundTable',
  components: {
    CpTable,
  },
  props: {},
  data() {
    return { fields };
  },
  methods: {
    ...mapActions('configuration', ['getTokenConfiguration']),
    getTokenConfigurationData() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      this.getTokenConfiguration({
        issuerId,
        tokenId,
      })
          .finally(() => {
            this.$emit('onTokenConfigurationChange', { isEvergreenRound: this.tokenConfiguration.evergreenRound });
            this.ready = true;
          });
    },
  },
  computed: {
    ...mapGetters({
      tokenConfiguration: 'configuration/getTokenConfiguration',
    }),
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    urlParams() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      return { issuerId, tokenId };
    },
    fieldsLocalized() {
      return this.fields.map((item) => {
        const targetKeys = ['minInvestmentFiat', 'minInvestmentCrypto'];
        if (targetKeys.includes(item.key)) {
          const field = {
            ...item,
            label: this.$t(item.i18nKey, [this.issuerMainCurrencyIdentifier]),
          };
          delete field.i18nKey;
          return field;
        }
        return item;
      });
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (!from) {
          this.ready = false;
          this.getTokenConfigurationData();
        } else if (to.params.tokenId !== from.params.tokenId) {
          this.$router.push({ query: { search: undefined } });
          this.getTokenConfigurationData();
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
  .action-button {
    border: 0 solid transparent;

    &:hover {
      background: transparent;
    }

    i {
      color: #1f537a;
      font-size: 0.894rem;
    }
  }

  .disguise {
    visibility: hidden;
  }
</style>
